<template>
  <div class="main" v-title data-title="中山市恒丰利织造有限公司">
    <el-backtop></el-backtop>
    <!-- 导航栏 -->
    <nav
      class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top"
    >
      <div class="container">
        <a class="navbar-brand"
          ><img
            src="../assets/images/logo.png"
            width="50"
            height="50"
            alt=""
            loading="lazy"
          />
          中山市恒丰利织造有限公司</a
        >
        <button
          class="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" style="margin-right: 18px">
              <a class="nav-link">关于我们</a>
            </li>

            <li class="nav-item" style="margin-right: 18px">
              <a class="nav-link">联系我们</a>
            </li>
            <li class="nav-item" style="margin-right: 18px">
              <a
                class="nav-link"
                v-if="isLogin == true"
                @click="goPage('OrderInfo')"
                >自助下单</a
              >
              <a class="nav-link" v-else @click="goPage('Login')">客户登录</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="goPage('CompanyLogin')" v-if="isLogin == false"
                >公司内部登录</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- 轮播图 -->
    <div
      id="carouselExampleCaptions"
      class="carousel slide auto-img"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleCaptions"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/images/001.jpg" class="d-block w-100" alt="..." />
          <div class="carousel-caption d-none d-md-block">
            <h2 style="margin-bottom: 20px">扁机领</h2>
            <p style="font-size:16px">恒丰利织造有限公司</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../assets/images/002.jpg" class="d-block w-100" alt="..." />
          <div class="carousel-caption d-none d-md-block">
            <h2 style="margin-bottom: 20px">自动间</h2>
            <p style="color:gray; font-size:16px">恒丰利织造有限公司</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../assets/images/003.jpg" class="d-block w-100" alt="..." />
          <div class="carousel-caption d-none d-md-block">
            <h2 style="margin-bottom: 20px">染厂染色</h2>
            <p style="font-size:16px">恒丰利织造有限公司</p>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <!-- Page Content -->
    <div class="container">
      <h3 class="my-4">欢迎来到恒丰利</h3>
      <p style="font-size: 0.8rem">WELCOME TO OUR COMPANY</p>
      <hr />

      <div class="companyAbout">
        <div class="companyTitle">公司简介</div>
        <div class="titleEn">COMPANY INFORMATION</div>
        <div class="companyContent">
          <p class="companyMain">
            恒丰利织造有限公司成立于1992年，位于广东省中山市沙溪镇云汉村云兴路27号，是一家专业生产扁机领、袖嘴、丝光提花领、收针领、罗纹袖口、衫脚、自动间布等系列产品的企业。本厂设备先进，技朮力量雄厚，拥有极具规模的机械设备及厂房，多年来本着精益求精的精神，结合国内外先进生产技朮与ISO管理体系，不断开拓进取。自2000年公司上了ERP系统以来，经过几年管理上的不断改进，已形成了同行业领先的管理模式。经过24年努力和累积，公司已发展成为集织、染、卖布、制衣于一体的集团公司；公司有上1000台织领机，在织领行业规模最大，有极强的暴发力；有12台自动间织布机，设备先进。现已成为多个国际知名时装、运动品牌的指定供应商。并与多家一流上市公司建立合作伙伴关系。恒丰利的专业是您需求的保证！
          </p>
          <div class="companyImg">
            <!-- <img class="auto-img" src="../assets/images/1.jpg" alt="" /> -->
            <img class="auto-img" src="../assets/images/1s.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
      <!-- Marketing Icons Section -->
    <div class="container">
      <div class="productAbout">
        <div class="companyTitle">生产介绍</div>
        <div class="titleEn">PRODUCT INFORMATION</div>
        <div class="row" style="margin-top: 30px">
          <div class="col-lg-4 mb-4">
            <div class="card h-100">
              <h4 class="card-header">扁机领</h4>
              <div class="card-body">
                一）电脑提花领：芝麻花领、格仔提花领、英文字提花领、收针领(牛角领)
                、双面领等各种不规则图案电脑提花领。
                <div>
                  二）直纹针织罗纹带：直纹间色罗纹带、直纹净色罗纹带收针领(牛角领)、之字罗纹带。
                </div>
                三）3 针 -18 针扁机领罗纹、衫脚、袖口： 1*1 扁机领、 2*2
                扁机罗纹、 3*3 扁机罗纹、 4*4 扁机罗纹。 四 )
                、人字带：棉织人字带、毛织人字、 PP 人字带。
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="card h-100">
              <h4 class="card-header">自动间布料</h4>
              <div class="card-body">
                一）四色及六色自动间布，排间布，每天产量可达自动间布4000磅。
                <div>
                  二）配备有数码计算机监控系统及严格的ISO质量管理体系，确保扁机领、自动间布的质量能达到国际认可水平。
                </div>
                三）多个十年以上工作经验的织领，全面掌握调改各种花色品种技术，
                并成立专业的技术开发部门，同时与各友好单位开展技术合作，长期提供技术创新支持。
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="card h-100">
              <h4 class="card-header">染厂染色</h4>
              <div class="card-body">
                一）本部经营染领、纱、布等业务。有专业染领拉缸、J缸，提供专业的染领服务。有10至1000磅各种型号纱缸，满足客户不同量订单的需求。有2吨/日的染布产能。
                <div>
                  二）本部设备齐全，先进，可满足客户的各种需求。质量好，交货快，返修率低。
                </div>
                三）熟识染整厂的工作流程及各种染料助剂的特性。对染后手感、强度、色泽、织物风格处理、颜色牢度等指针作严格测试。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="productDisplay">
        <div class="companyTitle">产品展示</div>
        <div class="titleEn">PRODUCT DISPLAY</div>

        <p style="color: #43627a; text-align: center; margin: 50px 0 20px">
          <i class="fa fa-hand-o-right" aria-hidden="true"></i
          ><span class="showTitle">电脑提花领</span
          ><i class="fa fa-hand-o-left" aria-hidden="true"></i>
        </p>
        <el-carousel :interval="4000" type="card" height="200px">
          <el-carousel-item v-for="(item, index) in bannerone" :key="index">
            <img class="" style="" :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>

        <p style="color: #43627a; text-align: center; margin: 50px 0 20px">
          <i class="fa fa-hand-o-right" aria-hidden="true"></i
          ><span class="showTitle">罗纹.衫脚.针织袖口.人字带</span
          ><i class="fa fa-hand-o-left" aria-hidden="true"></i>
        </p>
        <el-carousel :interval="4000" type="card" height="200px">
          <el-carousel-item v-for="(item, index) in bannertwo" :key="index">
            <img class="" style="" :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>

        <p style="color: #43627a; text-align: center; margin: 50px 0 20px">
          <i class="fa fa-hand-o-right" aria-hidden="true"></i
          ><span class="showTitle">自动间布</span
          ><i class="fa fa-hand-o-left" aria-hidden="true"></i>
        </p>
        <el-carousel :interval="4000" type="card" height="200px">
          <el-carousel-item v-for="(item, index) in bannerthree" :key="index">
            <img class="" style="" :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="contactUs">
        <div class="companyTitle">联系我们</div>
        <div class="titleEn">CONTACT US</div>

        <div class="contactMain">
          <div>
            <baidu-map
              class="map"
              :center="map.center"
              :zoom="map.zoom"
              @ready="handler"
            >
              <!--缩放-->
              <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
              <!--定位-->
              <bm-geolocation
                anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                :showAddressBar="true"
                :autoLocation="true"
              ></bm-geolocation>
              <!--点-->
              <bm-marker
                :position="map.center"
                :dragging="map.dragging"
                animation="BMAP_ANIMATION_DROP"
              >
                <!--提示信息-->
                <bm-info-window :show="map.show"
                  ><div style="color: #cc5522; font-weight:bold; margin-bottom:5px">中山市恒丰利织造有限公司</div><div style="font-size: 14px">广东省中山市沙溪镇云汉村云兴路27号</div></bm-info-window
                >
              </bm-marker>
            </baidu-map>
          </div>

          <div class="clearfix peoples">
            <div class="people">
              <div class="contactMess">
                <i class="fa fa-user" aria-hidden="true"></i>高文杰先生(董事长)
              </div>
              <div class="contactMess">
                <i class="fa fa-mobile fa-lg" aria-hidden="true"></i
                >手机：(0086)13809688348
              </div>
              <div class="contactMess">
                <i class="fa fa-envelope-o" aria-hidden="true"></i
                >Email：wjgao@hfl.me
              </div>
            </div>
            <div class="people">
              <div class="contactMess">
                <i class="fa fa-user" aria-hidden="true"></i>郑杰瑜小姐(总经理)
              </div>
              <div class="contactMess">
                <i class="fa fa-mobile fa-lg" aria-hidden="true"></i
                >手机：(0086)13809683343
              </div>
              <div class="contactMess">
                <i class="fa fa-envelope-o" aria-hidden="true"></i
                >Email：jyzheng@hfl.me
              </div>
            </div>

            <div class="people">
              <div class="contactMess">
                <i class="fa fa-user" aria-hidden="true"></i
                >薛海容小姐(业务总监)
              </div>
              <div class="contactMess">
                <i class="fa fa-mobile fa-lg" aria-hidden="true"></i>手机：
                (0086)18928135933
              </div>
              <div class="contactMess">
                <i class="fa fa-envelope-o" aria-hidden="true"></i
                >Email：hrxue@hfl.me
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.container -->

    <!-- Footer -->
    <footer class="py-5 bg-dark">
      <div class="container">
        <p class="m-0 text-center text-white copyright">
          Copyright &copy; 恒丰利织造有限公司 2021<a class="icp" @click="goBean"
            >粤ICP备17115312号</a
          >
        </p>
        <p class="m-0 text-center text-white copyright">
          <i class="fa fa-phone" aria-hidden="true"></i>电话：(0760)87797971
          <i class="fa fa-fax" aria-hidden="true" style="margin-left: 30px"></i
          >传真：(0760)87791775
          <i
            class="fa fa-map-marker"
            aria-hidden="true"
            style="margin-left: 30px"
          ></i
          >地址：广东省中山市沙溪镇云汉村云兴路27号
        </p>
      </div>
      <!-- /.container -->
    </footer>
  </div>
</template>

<script>
import "../assets/css/main.less";

export default {
  data() {
    return {
      username: "",
      cust_id: "",
      cust_name: "",
      isLogin: false,
      url: "http://beian.miit.gov.cn/",
      // 轮播图
      bannerone: [
        { url: require("../assets/images/2.jpg") },
        { url: require("../assets/images/3.jpg") },
        { url: require("../assets/images/4.jpg") },
        { url: require("../assets/images/5.jpg") },
        { url: require("../assets/images/6.jpg") },
        { url: require("../assets/images/7.jpg") },
        { url: require("../assets/images/8.jpg") },
        { url: require("../assets/images/9.jpg") },
      ],
      bannertwo: [
        { url: require("../assets/images/10.jpg") },
        { url: require("../assets/images/11.jpg") },
        { url: require("../assets/images/12.jpg") },
        { url: require("../assets/images/13.jpg") },
        { url: require("../assets/images/14.jpg") },
        { url: require("../assets/images/15.jpg") },
        { url: require("../assets/images/16.jpg") },
      ],
      bannerthree: [
        { url: require("../assets/images/17.jpg") },
        { url: require("../assets/images/18.jpg") },
        { url: require("../assets/images/19.jpg") },
        { url: require("../assets/images/20.jpg") },
        { url: require("../assets/images/21.jpg") },
        { url: require("../assets/images/22.jpg") },
        { url: require("../assets/images/23.jpg") },
        { url: require("../assets/images/24.jpg") },
        { url: require("../assets/images/25.jpg") },
        { url: require("../assets/images/26.jpg") },
      ],

      map: {
        center: { lng: 113.339613, lat: 22.530009 },
        zoom: 15,
        show: true
      },
    };
  },

  created() {
    this.getUserInfo();
    if (sessionStorage.getItem("user")) {
      this.isLogin = true;
    }
  },

  methods: {
    handler({ BMap, map }) {
      let me = this;
      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener("click", function (e) {
      });
    },

    // 获取用户信息
    getUserInfo() {
      this.username = sessionStorage.getItem("user");
      this.cust_id = sessionStorage.getItem("cust_id");
      this.cust_name = sessionStorage.getItem("cust_name");
    },

    goPage(name) {
      this.$router.push({ name });
    },

    goBean() {
      var r = confirm("您将要访问：http://beian.miit.gov.cn/，是否继续？");
      if (r == true) {
        window.location.href = this.url;
      }
    },
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 350px;
}
.el-carousel__item:nth-child(2n) {
  line-height: 200px;
  text-align: center;
}

.el-carousel__item:nth-child(2n + 1) {
  line-height: 200px;
  text-align: center;
}
</style>